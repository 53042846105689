<template>
  <div style="overflow:hidden;background-color: #FFFFFF">
    <v-snackbar class="pa-0" light timeout="800" v-model="alertShow" width="fit-content" top centered>
      <v-alert v-model="alertShow" dismissible type="success" text dense close-icon="close" style="margin-bottom: 0 !important;">
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <!--    <v-row class="hidden-md-and-up" style="height:75px">-->
    <!--      <v-col cols="12" class="mobile-header text-center">-->
    <!--        {{ $t(`label.history`) }}-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <!--    <v-card style="border-radius: unset">-->
    <!--      <v-row justify="space-around" no-gutters>-->
    <!--        <v-col cols="12">-->
    <!--          <v-menu>-->
    <!--            <template v-slot:activator="{ on, attrs }">-->
    <!--              <v-card class="mobile-menu-card-wrapper">-->
    <!--                <v-btn v-bind="attrs" v-on="on" block class="mobile-category-menuBtn">-->
    <!--                  {{ selectedCategory }}-->
    <!--                  <v-icon right large>mdi-menu-down</v-icon>-->
    <!--                </v-btn>-->
    <!--              </v-card>-->
    <!--            </template>-->
    <!--            <v-list>-->
    <!--              <v-list-item v-for="category in translatedCategories" :key="category.id"-->
    <!--                           @click="naviToSubHistory(category)" style="width: 100%">-->
    <!--                <router-link :to="category.routeName" style="text-decoration: unset !important;width: 100%;">-->
    <!--                  <span>{{ category.name }}</span>-->
    <!--                </router-link>-->
    <!--              </v-list-item>-->
    <!--            </v-list>-->
    <!--          </v-menu>-->
    <!--        </v-col>-->
    <!--      </v-row>-->
    <!--    </v-card>-->

    <v-row no-gutters class="betHistory-header-row" v-if="IsShowHeader">
      <v-col cols="6" class="text-center" style="padding-left:2px;">
        <!--        <span class="betHistory-header-title">Bets</span>-->
        <v-btn :to="routeName.MOBILE_BETTING_HISTORY" class="betHistory-header-title" active-class="selected">
          <!--          {{ $t(`mobileMenuTitle.bets`) }}-->
          <!--          <router-link :to="routeName.MOBILE_BETTING_HISTORY"  class="header-routerlink">-->
          <span>{{ $t(`mobileMenuTitle.bets`) }}</span>
          <!--          </router-link>-->
        </v-btn>
      </v-col>
      <v-col cols="6" class="text-center" style="padding-right:2px;">
        <v-btn :to="routeName.MOBILE_TRANSACTION_HISTORY" class="betHistory-header-title" active-class="selected">
          <!--          {{ $t(`mobileMenuTitle.transactions`) }}-->
          <!--          <router-link :to="routeName.MOBILE_TRANSACTION_HISTORY"  class="header-routerlink" >-->
          <span>{{ $t(`mobileMenuTitle.transactions`) }}</span>
          <!--          </router-link>-->
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="historyContainer">
        <router-view></router-view>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uiHelper, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'

export default {
  name: 'mobileHistory',
  data: () => ({
    selectedCategory: '',
    HistoryCategories: [],
    routeName: ROUTE_NAME,
    alertShow: false,
    alertMessage: ''
  }),
  computed: {
    IsShowHeader() {
      let currentRoute = this.$route.name

      return currentRoute == ROUTE_NAME.MOBILE_TRANSACTION_HISTORY || currentRoute == ROUTE_NAME.MOBILE_BETTING_HISTORY
    },
    translatedCategories() {
      let categories = [
        {
          name: locale.getMessage('mobileMenuTitle.betHistory'),
          routeName: this.routeName.MOBILE_BETTING_HISTORY,
          id: 1
        },
        {
          name: locale.getMessage('label.turnoverHistory'),
          routeName: this.routeName.MOBILE_TO_HISTORY,
          id: 2
        },
        {
          name: locale.getMessage('mobileMenuTitle.walletHistory'),
          routeName: this.routeName.MOBILE_TRANSACTION_HISTORY,
          id: 3
        }
        // {
        //   name: 'Promotion History',
        //   routeName: this.routeName.MOBILE_PROMO_HISTORY,
        //   id: 4
        // }
      ]

      return categories
    }
  },
  created() {
    console.log(this.$route.name)
    this.selectHistoryCat('')
  },
  methods: {
    selectHistoryCat(category, index) {
      if (category != '') {
        this.selectedCategory = category
      } else {
        let currentRoute = this.$route.name
        if (currentRoute.indexOf('betting-history') != -1) {
          this.selectedCategory = this.translatedCategories[0].name
        } else if (currentRoute.indexOf('turnover-history') != -1) {
          this.selectedCategory = this.translatedCategories[1].name
        } else if (currentRoute.indexOf('transaction-history') != -1) {
          this.selectedCategory = this.translatedCategories[2].name
        } else if (currentRoute.indexOf('promo-history') != -1) {
          this.selectedCategory = this.translatedCategories[3].name
        }
      }
    },
    naviToSubHistory(category) {
      this.selectHistoryCat(category.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-header {
  background-color: #000000;
  color: var(--v-newMobilePrimary-base);
  margin-top: auto;
  margin-bottom: auto;
}

.mobile-menu-card-wrapper {
  border-radius: 15px;
  margin: 15px 15px 10px 15px;
}

.mobile-category-menuBtn {
  justify-content: space-between;
  background-color: #f5f5f5;
}

.betHistory-header-row {
  margin: 18px 27px 18px 27px;
  border: 1px solid #e9e9e9;
  border-radius: 12px;
  height: 40px;

  .betHistory-header-title {
    color: #aaaaaa;
    height: 35px;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    border-radius: 12px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.02em;

    .header-routerlink {
      text-decoration: none;
    }

    &.selected {
      background-color: var(--v-newMobilePrimary-base);
      color: #ffffff;
    }
  }
}

.selected {
  background-color: var(--v-newMobilePrimary-base);
  color: #ffffff;
}
</style>
